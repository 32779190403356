<template>
  <div class="home">
    <h3 class="common-title">检索记录</h3>
    <div class="clearfix pb20">
    </div>
    <div class="home-left">
      <Empty v-if="historyList.length === 0"></Empty>
      <el-table
          :data="historyList"
          class="home-list"
          style="width: 100%"
          v-else>
        <el-table-column
            label="ID"
            prop="id"
            width="80">
        </el-table-column>
        <el-table-column
            prop="images"
            label="图片"
            width="150">
          <template #default="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.cos_path"
                :preview-teleported="true"
                fit="contain">
              <template #error>
                <el-icon class="image-slot">
                  <Picture/>
                </el-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="created_at"
            label="检索时间"
            :formatter="dateFormat">
        </el-table-column>
        <el-table-column
            prop="count"
            label="匹配数量">
        </el-table-column>

        <el-table-column label="操作" width="80px">
          <template #default="scope">
            <el-button
                type="primary"
                link
                @click="openHistoryDetailDialog(scope.row)"
                v-if="scope.row.count > 0">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="clearfix pt20 pb20">
      <el-pagination
          class="fr"
          background
          layout="prev, pager, next, sizes, total"
          :total="historyListFilter.totalSize"
          @current-change="getHistoryList"
          @size-change="getHistoryList(true)"
          v-model:current-page="historyListFilter.page"
          v-model:page-size="historyListFilter.size"
          :page-sizes="[10, 20, 50, 100]"
      />
    </div>

    <el-dialog v-model="historyDetailDialog" title="详情" width="70%" destroy-on-close align-center
               class="no-padding">

      <div class="home-right custom-upload" v-loading="loading">
        <Empty v-if="historyDetailList.length === 0"></Empty>
        <el-table
            :data="historyDetailList"
            class="home-list"
            style="width: 100%"
            v-else>
          <el-table-column
              label="ID"
              prop="id"
              width="80">
          </el-table-column>
          <el-table-column
              prop="images"
              label="图片"
              width="150">
            <template #default="scope">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.cos_path"
                  :preview-teleported="true"
                  fit="contain">
                <template #error>
                  <el-icon class="image-slot">
                    <Picture/>
                  </el-icon>
                </template>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
              prop="jan_code"
              label="JanCode">
          </el-table-column>
          <el-table-column
              prop="score"
              label="匹配度">
          </el-table-column>
          <el-table-column
              prop="goods.name"
              label="商品名"
              min-width="300">
          </el-table-column>

        </el-table>
      </div>
    </el-dialog>
    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from 'vue'
import {dayjs, ElMessage, ElMessageBox} from 'element-plus'
import {authApi} from '@/api'
import {onMounted} from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import {request} from "axios";
// @ is an alias to /src

export default {
  name: 'Home',
  components: {Empty},
  setup() {

    // history
    const historyList = ref([])
    const history = ref(null)
    const historyListFilter = reactive({
      page: 1,
      size: 20,
      totalSize: 0,
    })
    const getHistoryList = (reset) => {
      if (reset === true) {
        historyListFilter.page = 1
      }
      loading.value = true
      authApi.getSearchHistoryList(historyListFilter).then(res => {
        historyList.value = res.data.list
        historyListFilter.totalSize = res.data.total
      }).finally(() => {
        loading.value = false
      })
    }

    const loading = ref(false)

    const dateFormat = (row, column, cellValue) => {
      return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
    }

    // history detail
    const historyDetailList = ref([])
    const historyDetailDialog = ref(false)

    const openHistoryDetailDialog = (item) => {
      history.value = item
      historyDetailList.value = item.detail_list
      historyDetailDialog.value = true
    }

    onMounted(() => {
      getHistoryList()
    })
    return {
      loading,
      //history
      historyList,
      history,
      getHistoryList,
      dateFormat,
      historyListFilter,

      //history detail
      historyDetailList,
      historyDetailDialog,
      openHistoryDetailDialog,
    }
  }
}
</script>
<style scoped>
</style>

<style>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 23px;
}
</style>
